import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { newOrder, payTBank } from "../../api";
import arrowIcon from "../../img/arrow-left.svg";
import walletIcon from "../../img/wallet.svg";
import { toast } from "react-hot-toast";
import { getDeclination } from "../../utils/getDeclination";
import { DeliveryInfoBlock } from "./DeliveryInfoBlock";

import "./Order.css";

function formatNumberWithSpaces(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const Order = () => {
  const nav = useNavigate();
  const [cart, setCart] = useState([]);
  const [deliveryData, setDeliveryData] = useState("1");
  const [paymentData, setPaymentData] = useState("3");
  const [totalPrice, setTotalPrice] = useState(0);
  const [data, setData] = useState({
    name: "",
    phone: "",
    address: "",
    comment: "",
    companyName: "",
    inn: "",
  });
  const [canOrder, setCanOrder] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("cart")) {
      setCart(JSON.parse(localStorage.getItem("cart")));
    }

    if (localStorage.getItem("userInfo")) {
      const user = JSON.parse(localStorage.getItem("userInfo"));
      setData({
        name: user.name,
        phone: user.phone,
        address: user.address,
        companyName: user.company,
        inn: user.inn,
      });
    }
  }, []);

  const goBack = () => {
    nav("/cart");
  };

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));

    let price = 0;
    cart.forEach((product) => {
      price += product.price * product.quantity * product.inBox;
    });
    setTotalPrice(parseInt(price));
  }, [cart]);

  useEffect(() => {
    if (data.name && data.phone && data.companyName && data.inn.length == 12) {
      if (deliveryData == "2") {
        if (!data.address) {
          return () => {};
        }
      }
    } else {
    }
  }, [data, deliveryData]);

  const createOrder = async () => {
    console.log("createOrder");
    if (!canOrder) return;
    setCanOrder(false);
    const order = {
      ...data,
      delivery: deliveryData == "1" ? "Самовывоз" : "Курьером",
      payBy:
        paymentData == "3"
          ? "Наличными"
          : paymentData == "4"
          ? "Картой"
          : "Счет",
      products: cart.map((product) => ({
        id: product.id,
        name: product.article,
        quantity: product.quantity,
        price: parseInt(product.price),
        inBox: product.inBox,
      })),
    };

    const orderData = await newOrder(order);

    if (orderData && paymentData !== "3") {
      try {
        const bankResponse = await payTBank(orderData.orderID);
        window.location.href = bankResponse?.url;
      } catch (error) {
        toast.error("Ошибка оплаты");
      }
    } else {
      localStorage.removeItem("cart");
    }
    setCart([]);
    toast.success(
      "Заказ оформлен, наш менеджер в ближайшее время с Вами свяжется"
    );
    setTimeout(() => {
      nav("/");
      setData({
        name: "",
        phone: "",
        address: "",
        comment: "",
        companyName: "",
        inn: "",
      });
    }, 3000);
  };

  const totalWithSpaces = `${formatNumberWithSpaces(totalPrice)} р`;

  return (
    <>
      <div className="container-order-data">
        <div className="order-form">
          <div className="header-order">
            <img
              src={arrowIcon}
              style={{
                marginBottom: 12,
              }}
              onClick={() => {
                nav("/cart");
              }}
            />
            <div>
              <h3>Корзина</h3>
              <span className="gray-7d">
                {getDeclination(cart.length, ["товар", "товара", "товаров"])},{" "}
                {totalWithSpaces}
              </span>
            </div>
          </div>
          <div className="order-form-select-group mt-20">
            <div className="order-choise-btn-label">
              <select
                className="choise-btn"
                value={deliveryData}
                onChange={(e) => {
                  setDeliveryData(e.target.value);
                }}
              >
                <option value="1">Самовывоз</option>
              </select>
            </div>
            <div
              className="order-choise-btn-label"
              value={paymentData}
              onChange={(e) => {
                setPaymentData(e.target.value);
              }}
            >
              <select className="choise-btn">
                <option value="3">Наличными</option>
                <option value="4">Онлайн</option>
              </select>
            </div>
          </div>
          <h2 className="section-label">Получатель</h2>
          <div className="form-group-section">
            <div className="form-group">
              <input
                type="text"
                className="formInput"
                value={data.name}
                onChange={(e) => {
                  setData({
                    ...data,
                    name: e.target.value,
                  });
                }}
                placeholder="ФИО"
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="formInput"
                placeholder="Название компании"
                value={data.companyName}
                onChange={(e) => {
                  setData({
                    ...data,
                    companyName: e.target.value,
                  });
                }}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="formInput"
                placeholder="ИНН"
                value={data.inn}
                onChange={(e) => {
                  setData({
                    ...data,
                    inn: e.target.value,
                  });
                }}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="formInput"
                placeholder="Телефон"
                value={data.phone}
                onChange={(e) => {
                  setData({
                    ...data,
                    phone: e.target.value,
                  });
                }}
              />
            </div>
            {deliveryData == "2" && (
              <div className="form-group">
                <input
                  type="text"
                  className="formInput"
                  placeholder="Адрес доставки"
                  value={data.address}
                  onChange={(e) => {
                    setData({
                      ...data,
                      address: e.target.value,
                    });
                  }}
                />
              </div>
            )}
            <div className="form-group">
              <input
                type="text"
                className="formInput"
                placeholder="Комментарий"
                onChange={(e) => {
                  setData({
                    ...data,
                    comment: e.target.value,
                  });
                }}
                value={data.comment}
              />
            </div>
          </div>
          <DeliveryInfoBlock />
          <div className="count-block card-block-element">
            <span className="white-weight-text">Оплата</span>
            <div className="count-block-gray">
              <span className="gray-text">Товары, {cart.length} шт</span>
              <span className="gray-text end">{totalWithSpaces}</span>
              {/* скрыто <span className="gray-text">Моя скидка</span>
              <span className="gray-text end">?? р</span> */}
            </div>
            <div className="flex justify-between align-center">
              <h3 className="white-big-text">Итого:</h3>
              <span className="white-big-text">{totalWithSpaces}</span>
            </div>
          </div>
          {/* <div className="delivery-description text-red"></div> */}
          <p className="politic-block-text">
            <a
              href="(https://spruton.ru/legal/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              Политика конфиденциальности
            </a>{" "}
            и{" "}
            <a
              href="https://spruton.ru/legal/rules/"
              target="_blank"
              rel="noreferrer"
            >
              пользовательское соглашение
            </a>
          </p>
        </div>
        <div className="bottom-card-button">
          <button
            className="add-button full order-button"
            onClick={createOrder}
          >
            <div className="flex align-center gap-15">
              <img src={walletIcon} alt="wallet" />
              <span>К оформлению</span>
            </div>

            <span className="price-span">
              {formatNumberWithSpaces(totalPrice)} р
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Order;
