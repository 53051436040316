import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import arrowIcon from "../../img/arrow-left.svg";
import arrow2Icon from "../../img/arrow-right.svg";
import { useAppContext } from "../contextWrapper/AppContext";
import { calculateQuantity } from "../../utils/calculatePrice";

function formatNumberWithSpaces(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const OrderInfo = () => {
  const nav = useNavigate();
  const { id } = useParams();
  const { userInfo } = useAppContext();

  const activeOrder = useMemo(
    () =>
      userInfo?.orders &&
      userInfo?.orders.find((order) => String(order.orderId) === id),
    [userInfo, id]
  );

  if (!activeOrder) {
    return null;
  }

  console.log(activeOrder);

  const totalCount = activeOrder.products.reduce((acc, product) => {
    acc +=
      parseInt(product.quantity * product.inBox) % product.inPackage !== 0
        ? Math.ceil(product.quantity * product.inBox)
        : parseInt(product.quantity * product.inBox);
    return acc;
  }, 0);

  const totalPrice = activeOrder.products.reduce((acc, product) => {
    acc +=
      parseInt(product.quantity * product.inBox) % product.inPackage !== 0
        ? Math.ceil(product.quantity * product.inBox) * product.price
        : parseInt(product.quantity * product.inBox) * product.price;
    return acc;
  }, 0);

  return (
    <>
      <div className="wrapper-content container-order">
        <div className="page-title">
          <img
            src={arrowIcon}
            onClick={() => {
              nav("/orders");
              window.Telegram.WebApp.HapticFeedback.impactOccurred("light"); // вибрация
            }}
          />
          <div className="card-block-element-title">
            <h3>Заказ №{id}</h3>
          </div>
        </div>

        <div className="order_info_1">
          <div className="order-info">
            {/* <h4>{new Date(activeOrder.orderDate * 1000).toLocaleString()}</h4> */}
            <h4>
              {(() => {
                const date = new Date(activeOrder.orderDate * 1000);
                const day = date.getDate().toString().padStart(2, "0");
                const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Oylarda 0 dan boshlanadi
                const year = date.getFullYear();
                const hours = date.getHours().toString().padStart(2, "0");
                const minutes = date.getMinutes().toString().padStart(2, "0");

                return `${day}.${month}.${year} ${hours}:${minutes}`;
              })()}
            </h4>
            <div className="flex column gap-15">
              <div className="flex justify-between">
                <span>Товары, {totalCount} шт.</span>
                <span>{formatNumberWithSpaces(totalPrice)} Р</span>
              </div>
              <div className="flex justify-between">
                <span>Моя скидка</span>
                <span>{activeOrder.discount} р</span>
              </div>
              <div className="flex justify-between">
                <h3>Итого:</h3>
                <h3>{formatNumberWithSpaces(totalPrice)} Р</h3>
              </div>
            </div>
          </div>

          <p className="gray-7d mt-15">Покупки</p>
          <div className="card-block-list orderInfo orderInfo_2">
            {activeOrder &&
              activeOrder.products.map((product, index) => (
                <div
                  key={index}
                  className="cart-item-row"
                  style={{ borderBottom: "2px solid #1c1c1c" }}
                  onClick={() => {
                    localStorage.setItem("product", JSON.stringify(product));
                    nav(`/product/${product.productID}`);
                  }}
                >
                  <div
                    className="cart-item-picture_1"
                    onClick={() => {
                      if (parseInt(product.inStock <= 0)) return;
                      localStorage.setItem(
                        "product",
                        JSON.stringify({
                          ...product,
                        })
                      );
                      nav("/product/" + product.productID);
                    }}
                  >
                    <img
                      src={`https://shop-api.toyseller.site/api/image/${product.productID}/${product.image}`}
                      alt="picture"
                    />
                  </div>
                  <div className="cart-item-data_1">
                    <div className="cart-item-label">
                      <h3>{product.article}</h3>
                      <div className="cart-item-caption">
                        <span>
                          {product?.textColor.replace("/", ", ") || "——"}
                        </span>
                        <span>
                          Кол-во товаров: {calculateQuantity(product)} шт.
                        </span>
                      </div>
                      <h3 className="price-bottom">
                        {formatNumberWithSpaces(
                          parseInt(product.quantity * product.inBox) %
                            product.inPackage !==
                            0
                            ? Math.ceil(product.quantity * product.inBox) *
                                product.price
                            : parseInt(product.quantity * product.inBox) *
                                product.price
                        )}{" "}
                        ₽
                      </h3>
                    </div>
                    <div className="cart-right-block">
                      <img src={arrow2Icon} alt="arrow2Icon" />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderInfo;

// let rr = {
//   id: 2917,
//   categoryID: 40,
//   categoryName: "Кроссовки",
//   subCategoryName: "Летняя",
//   photo: "1734017926_2024-12-12_18-26-05.png",
//   article: "1014A346-500_25",
//   price: "10180",
//   discountedPrice: "10180",
//   recomendedMinimalSize: "1",
//   boxSize: "1",
//   packageSize: "1",
//   inStock: "1",
//   isNew: "true",
//   description:
//     "Детские беговые кроссовки для уличных тренировок в теплое время года и занятий в зале. Подойдет для людей с нейтральной пронацией, которым не требуется дополнительная поддержка стопы. Яркий дизайн. Сетчатых верх с хорошей вентиляцией. Стабилизация пятки. Фирменная технология GEL, поглащающая ударную энергию в пяточной области. Амортизирующая межподошва из EVA, резиновая подметка.\r\n\r\n• вес ½ пары: 200 грамм;\r\n• воздухопроницаемый сетчатый верх;\r\n• ударопоглащающая технология GEL;\r\n• амортизирующая межподошва из EVA;\r\n• резиновая подметка.",
//   review: "",
//   keywords: ["83"],
//   otherPhotos: [
//     "1734017932_2024-12-12_18-27-19.png",
//     "1734017932_2024-12-12_18-26-49.png",
//     "1734017932_2024-12-12_18-27-42.png",
//     "1734017932_2024-12-12_18-28-06.png",
//     "1734017933_2024-12-12_18-28-26.png",
//     "1734017933_2024-12-12_18-28-49.png",
//   ],
//   rutubeReview: "",
//   textColor: "фиолетовый/разноцветный",
//   status: 70,
//   modelName: "1014A346",
//   producingCountry: 0,
//   minKidAge: 66,
//   maxKidAge: 68,
//   kidGender: 70,
//   color: 81,
//   tradeMarkID: 4,
//   tradeMarkName: "Asics",
//   tradeMarkLogo: "1734017463_2024-12-12_18-30-16.png",
//   tradeMarkAbout:
//     "Японский бренд входит в пятерку крупнейших мировых производителей спортивной одежды, обуви и аксессуаров. Asics славится инновациями в производстве спортивной обуви - именно они создали первую ударопоглощающую подошву, применили технологию охлаждения стопы, начали использовать светоотражающие полоски на обуви и разработали специальные модели для женщин-спортсменок. Самое авторитетное в беговом мире издание «Runner’s World» четыре раза присуждало Asics звание лучших кроссовок для бега (в 2005, 2007-2009 годах). Сегодня ключевая особенность знаменитых беговых кроссовок - технология ASICS Gel® с эффективной амортизацией, снижающей давление и напряжение во время бега. В ассортименте Asics кроссовки для бега по любым поверхностям: асфальту, грунту, снегу, льду, для спортсменов и любителей с разным типом пронации.",
//   shoeSizeName: "25",
//   shoeSizeClass: "73",
//   shoeSizeLength: "155",
//   shoeSizeRu: "25",
//   shoeSizeEu: "25",
//   image: "1734017926_2024-12-12_18-26-05.png",
//   inBox: "1",
//   inTheBox: "1",
//   inPackage: "1",
//   new: "true",
//   keyWords: ["83"],
//   otherImages: [
//     "1734017932_2024-12-12_18-27-19.png",
//     "1734017932_2024-12-12_18-26-49.png",
//     "1734017932_2024-12-12_18-27-42.png",
//     "1734017932_2024-12-12_18-28-06.png",
//     "1734017933_2024-12-12_18-28-26.png",
//     "1734017933_2024-12-12_18-28-49.png",
//   ],
//   inCart: 0,
// };
