import { useEffect } from "react";
import { useNavigate } from "react-router";
import { getUser } from "../../api";
import arrowIcon from "../../img/arrow-left.svg";
import { useAppContext } from "../contextWrapper/AppContext";
import "./Orders.css";

function formatNumberWithSpaces(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const Orders = () => {
  const nav = useNavigate();

  const { userInfo, setUserInfo } = useAppContext();

  useEffect(() => {
    const fetchData = async () => {
      const user = await getUser();
      localStorage.setItem("userInfo", JSON.stringify(user));
      setUserInfo(user);
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="container-order">
        <div className="page-title">
          <img
            src={arrowIcon}
            onClick={() => {
              nav("/");
            }}
          />
          <div className="card-block-element-title">
            <h3>История заказов</h3>
          </div>
        </div>

        <div className="wrapper-content orders">
          {userInfo?.orders &&
            userInfo?.orders.map((order) => (
              <div
                className="cart-item-row"
                style={{ borderBottom: "2px solid #1c1c1c" }}
                onClick={() => {
                  localStorage.setItem("order", JSON.stringify(order));
                  nav(`/orderInfo/${order.orderId}`);
                  window.Telegram.WebApp.HapticFeedback.impactOccurred("light"); // вибрация
                }}
              >
                <div
                  className="cart-item-data"
                  style={{
                    alignItems: "center",
                  }}
                >
                  <div className="cart-item-label">
                    <h3>Заказ #{order.orderId}</h3>
                    <div className="info-order">
                      <span className="gray-7d fs-14">Дата заказа: </span>
                      <span className="gray-7d fs-14">
                        {(() => {
                          const date = new Date(order.orderDate * 1000);
                          const day = date
                            .getDate()
                            .toString()
                            .padStart(2, "0");
                          const month = (date.getMonth() + 1)
                            .toString()
                            .padStart(2, "0"); // Oylarda 0 dan boshlanadi
                          const year = date.getFullYear();
                          const hours = date
                            .getHours()
                            .toString()
                            .padStart(2, "0");
                          const minutes = date
                            .getMinutes()
                            .toString()
                            .padStart(2, "0");

                          return `${day}.${month}.${year} ${hours}:${minutes}`;
                        })()}
                      </span>
                      <br />
                      <span className="gray-7d fs-14">Кол-во товаров: </span>
                      <span className="gray-7d fs-14">
                        {order.products.length}
                      </span>
                    </div>
                    <div className="info_order_bottom">
                      <span className="price-orders">
                        {formatNumberWithSpaces(parseInt(order.total))} р
                      </span>
                      <button
                        onClick={() => {
                          localStorage.setItem("order", JSON.stringify(order));
                          nav(`/orderInfo/${order.orderId}`);
                        }}
                      >
                        Подробнее
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default Orders;
