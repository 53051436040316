import { useNavigate } from "react-router";
import arrowIcon from "../../img/arrow-left.svg";
import "./Terms.css";

const Terms = () => {
  const nav = useNavigate();

  return (
    <>
      <div className="container-order">
        <div className="page-title">
          <img
            src={arrowIcon}
            onClick={() => {
              nav("/");
            }}
          />
          <div className="card-block-element-title">
            <h3>Условия работы</h3>
          </div>
        </div>

        <ul className="terms order-form">
          <li>
            <a
              href="https://spruton.ru/legal/toymarket_service_agreement/"
              target="_blank"
              rel="noreferrer"
            >
              Договор на оказание услуг по размещению товарных предложений на
              сервисе Тоймаркет
            </a>
          </li>
          <li>
            <a
              href="https://spruton.ru/legal/toymarket_smz_conditions/"
              target="_blank"
              rel="noreferrer"
            >
              Условия размещения товарных предложений самозанятых на сервисе
              Тоймаркет
            </a>
          </li>
          <li>
            <a
              href="https://spruton.ru/legal/toymarket_service_conditions/"
              target="_blank"
              rel="noreferrer"
            >
              Условия оказания услуг Тоймаркет по размещению товарных
              предложений
            </a>
          </li>
          <li>
            <a
              href="https://spruton.ru/legal/toymarket_services_rate_table/"
              target="_blank"
              rel="noreferrer"
            >
              Процентные ставки для расчета стоимости услуг и вознаграждения
              Исполнителя (модель DBS)
            </a>
          </li>
          <li>
            <a
              href="https://spruton.ru/legal/toymarket_offer_requirements/"
              target="_blank"
              rel="noreferrer"
            >
              Требования к Товарным предложениям и их формату
            </a>
          </li>
          <li>
            <a
              href="https://spruton.ru/legal/toymarket_adv_rules/"
              target="_blank"
              rel="noreferrer"
            >
              Требования к материалам
            </a>
          </li>
          <li>
            <a
              href="https://spruton.ru/legal/toymarket_termsofuse/"
              target="_blank"
              rel="noreferrer"
            >
              Правила использования сервиса Тоймаркет
            </a>
          </li>
          <a
            href="https://spruton.ru/legal/toymarket_license_agreement/"
            target="_blank"
            rel="noreferrer"
          >
            Лицензионное соглашение на использование сервиса Тоймаркет
          </a>
        </ul>
      </div>
    </>
  );
};

export default Terms;
